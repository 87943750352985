.podcast-listing-main-container {
  width: 100%;
}

#podcast-listing-wrapper {
  margin: 0 auto;
}
.view-podcasts {
  padding-left: 30px;
  overflow: auto;
}

.view-podcasts h2 {
  align-self: flex-start;
  margin-bottom: 30px;
  margin-left: 15px;
}

.podcast-listing-container {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 40% 20% 20% 20%;
  margin-bottom: 20px;
  background: rgba(196, 196, 196, 0.2);
  border-radius: 32px;
  text-align: center;
  padding: 15px;
  align-content: center;
}

.podcast-listing-container button {
  background-color: transparent;
  width: 120px;
  padding: 5px 8px;
  border: none;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.podcast-listing-container button:hover {
  border-radius: 32px;
  padding: 2px 8px;
}
.podcast-listing.header {
  width: 80%;
  overflow: auto;
}

.podcast-listing-container:first-child {
  width: 80%;
  height: fit-content;
  background: none;
  color: #273f48;
  padding: 0px;
  padding-left: 15px;
}

.podcast-listing-container span {
  width: 100%;
  display: flex;
  align-items: center;
}
.podcast-listing-container:first-child .podcast-listing.header label {
  height: 100%;
  padding-left: 15px;
}

.podcast-listing.header label,
.podcast-listing.header p {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-align: left;
}

.podcast-listing.header a {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  background: none;
  width: fit-content;
  color: #273f48;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
}

.podcast-listing.header a:hover {
  text-decoration: underline;
  color: #e2514c;
  text-underline-offset: 3px;
}

.podcast-listing.header a svg {
  margin-left: 10px;
}

.header-select-all {
  margin-left: 15px;
}

.image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.image-container p {
  margin: 0px;
  font-weight: 600;
  margin-left: 10px;
  height: fit-content;
  align-self: center;
}

.image-container img {
  width: 45px;
  height: 45px;
  margin-left: 15px;
  border-radius: 8px;
}

/* Podcast Success Message */

.upload-box {
  position: relative;
}

.podcast-success-message {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  font-weight: 700;
  width: 50%;
  height: 80%;
  top: 50%;
  transform: translateY(-50%);

  position: absolute;
  border-radius: 32px;
}

.podcast-success-message h4 {
  color: #273f48;
  font-size: 1rem;
}

#podcast-creation-loader {
  width: 100%;
  height: 100%;
  position: absolute;
}

#podcast-creation-loader > .loader-container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

#dashboard-podcast-page h2 {
  margin-left: 0px;
}

.podcast-badge {
  margin-left: 5px;
  background-color: #273f48;
  color: white;
  padding: 5px 8px;
  border-radius: 10px;
}

.nav-link.active span {
  background-color: #e2514c;
}
