#subscriptions-main-container {
  height: 100%;
  position: relative;
  padding: 20px;
}

#subscriptions-main-container .loading-container svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#subscriptions-main-container .slide-item {
  width: fit-content;
  box-shadow: none;
}
#subscriptions-main-container .slide-item button {
  border: 1px solid #e2514c;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 2px 15px;
  font-weight: normal;
  font-size: 12px;
  width: 100%;
  font-weight: normal;
  letter-spacing: 0.15px;
  color: #656565;
  margin-top: 10px;
  background-color: white;
}

#subscriptions-main-container .slide-item button:hover {
  background-color: #e2514c;
  color: white;
  transition: all 0.3s ease-in-out;
}

#subscriptions-main-container .slide-item img {
  width: 120px;
  height: 120px;
  margin-bottom: 0px;
  border-radius: 32px;
}

.icon-error-container {
  background-color: teal;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-container p {
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #4d4d4d;
}

#subscriptions-main-container .slide-item h3 {
  height: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #273f48;
  margin-bottom: 0px;
  margin-top: 6px;
}

#subscriptions-main-container h6 {
  margin: 20px 0px;
}
#subscriptions-main-container .subscription-list-container {
  display: flex;
}
