.carousel {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

#carousel-empty h4 {
  color: #e2514c;
  width: fit-content;
  margin: 20px 15px;
  font-weight: 0.7rem;
  font-weight: 500;
}

.image-slide {
  height: 100px;
  width: 100px;
  transition: background-image 0.3s ease-in-out;
}

.slide-arrow {
  z-index: 2;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 32px;
  font-size: 2rem;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-arrow.right {
  right: -0.3rem;
}

.slide-arrow.right.hide,
.slide-arrow.left.hide {
  display: none;
}

.slide-arrow.left {
  left: -0.3rem;
}

.slide-item {
  transition: all 0.5s ease-in-out;
  margin: 16px;
}

.slide-item:hover {
  transform: scale(1.1);
}

.carousel-image-container {
  display: flex;
}

.slide-item h3 {
  margin: 0px;
  margin-bottom: 3px;
  padding: 0px;
  width: 100px;
  font-weight: 500;
  font-size: 0.8rem;
}

.slide-item {
  background: #ffffff;
  border-radius: 0px 0px 12px 12px;
  text-decoration: none;
  border-radius: 12px;
}

#carousel img {
  cursor: pointer;
  pointer-events: all;
  width: 100px;
  height: 120px;
  border-radius: 5px;
  margin-bottom: 15px;
  object-fit: cover;
}

#carousel:hover {
  transform: scale(1.1);
}

.slide-item p {
  word-wrap: break-word;
  margin: 0px;
  padding: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /* identical to box height, or 200% */

  color: #273f48;
}

.slide-item-desc,
.image-slide {
  cursor: pointer;
}

.slide-item-desc p::first-letter {
  text-transform: capitalize;
}
