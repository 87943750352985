html {
  height: 100vh;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  color: #273f48;
}
body {
  position: relative;
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

.btn {
  color: white !important;
  cursor: pointer;
  padding: 10px 16px;
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;
  border-radius: 32px;
  outline: none;
  transition: all 0.3s ease-in;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
}

.white {
  background-color: white;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #273f48;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(226, 81, 76, 1);
}

.modal {
  z-index: 1000000;
}
