.reset-page-main {
  background: linear-gradient(to left, #e2514c 50%, #ffffff 50%);
  height: 100vh;
  position: relative;
}

.reset-page-header {
  height: 18vh;
  width: 80%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
}
.header-container img {
  width: 120px;
}
.header-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-container .links {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-container a:hover {
  text-decoration: underline;
}

.auth-signup-button {
  min-width: 120px;
  border: 2px solid #e2514c;
}

.auth-signup-button:hover {
  color: white;
  background: #e2514c;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  border-radius: 32px;
}

.reset-page-body {
  height: 100vh;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 40% 60%;
  margin-left: 10%;
}

.reset-page-body p {
  margin-bottom: 20px;
  max-width: 320px;
  color: #273f48;
  font-weight: 600;
  margin-bottom: 40px;
  font-size: 0.8rem;
}

.reset-page-body aside:last-of-type {
  background: url("../../../assets//images/resetpassword-image.png");
  background-size: cover;
}

.reset-page-body aside:first-of-type {
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-self: center;
  min-width: 400px;
}

.reset-page-body aside:first-of-type h1 {
  justify-self: flex-start;
  align-self: start;
  color: #e2514c;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 20px;
}

.reset-page-body .reset-form {
  display: flex;
  flex-direction: column;
}

.reset-page-body .reset-form input[type="password"],
.reset-page-body .reset-form input[type="email"],
.reset-page-body .reset-form input[type="text"] {
  width: 350px;
  height: 36px;
  border: none;
  border-bottom: 1px solid #ced4da;
  box-sizing: border-box;
  outline: none;
}

.label-password,
.reset-form-label {
  position: relative;
  height: 90px;
}

.label-password .far.fa-eye,
.label-password .far.fa-eye-slash {
  color: #273f48;
  position: absolute;
  bottom: 42px;
  right: 15px;
  font-size: 1.2rem;
  transition: all 0.5s ease-in;
  z-index: 2;
}

.reset-page-body .reset-form input:focus {
  border-bottom: 1px solid #e2514c;
}

.reset-form-label {
  position: relative;
  width: 350px;
  margin-bottom: 5px;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
}

.reset-button {
  color: white;
  outline: none;
  font-weight: 500;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 350px;
  background: rgb(226, 95, 90);
  box-shadow: 0px 8px 16px rgba(226, 81, 76, 0.15);
  border: none;
  border-radius: 32px;
  transition: all 0.3s ease-in-out;
}

button.reset-button:hover {
  background: rgb(226, 70, 70);
  transform: translateY(-3px);
}

.reset-button:disabled a {
  color: #273f48 !important;
}
.reset-button:disabled,
.reset-button:disabled:hover {
  cursor: unset;
  background-color: rgba(39, 63, 72, 0.2);
  /* color: rgba(0, 0, 0, 0.6); */
  box-shadow: 0px 8px 16px rgba(216, 155, 153, 0.15);
}

.reset-form-remember {
  font-size: 0.85rem;
  margin-top: 12px;
  position: relative;
  padding-left: 25px;
  color: #273f48;
  font-weight: 600;
}

.reset-form-remember input {
  position: absolute;
  opacity: 1;
  cursor: pointer;
  height: 0;
  width: 0;
}

p.error {
  margin-top: 7px;
  margin-bottom: 8px;
  color: rgba(226, 81, 76, 0.9);
  font-size: 0.7rem;
  font-weight: 500;
}

.Modal {
  color: #273f48;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 250px;
  background-color: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
}

.Modal button {
  padding: 10px 20px;
  width: fit-content;
}

.Overlay {
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(39, 63, 72, 0.9);
}

.Overlay h1 {
  width: 80%;
  padding: 3px;
  font-size: 1rem;
}

@media screen and (max-width: 500px) {
  .reset-page-main {
    background: #ffffff;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-container .links {
    width: 60%;
  }

  .header-container a {
    color: #273f48;
  }

  .reset-page-body aside:last-of-type {
    display: none;
  }

  .reset-page-body {
    height: 100vh;
    margin: 0 auto;
  }

  .reset-page-body aside:first-of-type {
    background-color: #ffffff;
    min-width: 400px;
  }
}
