.auth-page-main {
  background: linear-gradient(to left, #e2514c 50%, #ffffff 50%);
  height: 100vh;
  position: relative;
}

.auth-page-header {
  z-index: 3;
  height: 18vh;
  width: 80%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
}
.header-container img {
  width: 120px;
}
.header-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-container .links {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.links a {
  min-width: 140px;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.15px;
  text-decoration: none;
  margin-right: 20px;
  color: #ced4da;
}

.links button a {
  color: #e2514c;
  padding: 10px 16px;
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;
  margin-right: 0;
}

.links button:hover a {
  color: #ffffff;
  text-decoration: none;
}

.auth-page-body a {
  margin-right: 0px;
}

.header-container a:hover {
  text-decoration: underline;
}

.auth-page-body {
  height: 100vh;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 40% 60%;
  margin-left: 10%;
}

.auth-page-body p {
  margin-bottom: 20px;
}

.auth-page-body aside:last-of-type {
  background: url("../../../assets//images/signin-image.png");
  background-size: cover;
  position: relative;
}

.auth-page-body aside:last-of-type > section:first-child {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(231, 22, 22, 0.3) -10.37%,
      rgba(235, 17, 17, 0) 65.09%
    ),
    rgba(226, 81, 76, 0.9);
  mix-blend-mode: multiply;
  color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth-page-body aside:last-of-type > section:first-child h1 {
  font-size: 54px;
  line-height: 66px;
  align-items: center;
  letter-spacing: 0.15px;
}
.auth-page-body aside:last-of-type > section:first-child p {
  color: white;
  font-weight: 450;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.15px;
  width: 416px;
}
/* 
.signup-intro {
} */

.auth-page-body aside:first-of-type {
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-self: center;
  min-width: 400px;
  position: relative;
}

.auth-page-body aside:first-of-type h1 {
  justify-self: center;
  justify-self: flex-start;
  color: #e2514c;
  font-size: 30px;
  line-height: 45px;
}

.auth-page-body .signup-form {
  display: flex;
  flex-direction: column;
}

.auth-page-body .signup-form input[type="password"],
.auth-page-body .signup-form input[type="email"],
.auth-page-body .signup-form input[type="text"] {
  width: 350px;
  height: 36px;
  border: none;
  border-bottom: 1px solid #ced4da;
  box-sizing: border-box;
  outline: none;
}

.auth-page-body .signup-form input[type="password"] {
  padding-right: 40px;
}

.label-password,
.signup-form-label {
  position: relative;
}

.label-password .far {
  color: #273f48;
  position: absolute;
  bottom: 24px;
  right: 15px;
  font-size: 1.2rem;
  transition: all 0.5s ease-in;
  z-index: 2;
}

.auth-page-body .signup-form input:focus {
  border-bottom: 1px solid #e2514c;
}

.signup-form-label {
  position: relative;
  width: 350px;
  margin-top: 8px;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.signup-button {
  padding: 10px 30px;
  outline: none;
  width: 350px;
  background: rgb(226, 95, 90);
  box-shadow: 0px 8px 16px rgba(226, 81, 76, 0.15);
  border: none;
  border-radius: 32px;
  transition: all 0.4s ease-in-out;
  font-weight: 500;
}

.signup-button:hover {
  transform: translateY(-3px);
}

.signup-button a {
  color: white;
}

.signup-button:disabled,
.signup-button:disabled:hover {
  cursor: unset;
  background-color: rgba(39, 63, 72, 0.2);
  color: rgba(39, 63, 72, 0.6);
  box-shadow: 0px 8px 16px rgba(216, 155, 153, 0.15);
}

.signup-button:disabled a,
.signup-button:disabled:hover a {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
}

.signup-button:hover a {
  color: white;
  text-decoration: none;
}

.auth-page-body p,
.signup-intro a,
.forgot-password-link {
  font-size: 0.8rem;
  color: #273f48;
  font-weight: 600;
}
.forgot-password-link {
  text-align: center;
  margin-top: 20px;
  width: 350px;
}
.forgot-password-link a {
  font-size: 0.9rem;

  text-align: center;
  color: #d23e39;
  text-decoration: none;
}
.signup-intro a:hover {
  color: #e2514c;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 18px;
  width: 18px;
  border: 1px solid #e2514c;
  border-radius: 4px;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.label-container input:checked ~ .checkmark {
  background-color: #e2514c;
  border: 1px solid #e2514c;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.label-container input:checked ~ .checkmark:after {
  display: block;
}

.label-container .checkmark:after {
  left: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

p.error {
  margin-top: 7px;
  margin-bottom: 8px;
  color: rgba(226, 81, 76, 0.9);
  font-size: 0.7rem;
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  .auth-page-main {
    background: #ffffff;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-container .links {
    width: 60%;
  }

  .header-container a {
    color: #273f48;
  }

  .auth-page-body aside:last-of-type {
    display: none;
  }

  .auth-page-body {
    height: 100vh;
    margin: 0 auto;
  }

  .auth-page-body aside:first-of-type {
    background-color: #ffffff;
    min-width: 400px;
  }
}
