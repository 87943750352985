.loader-container {
  z-index: 10000;
  top: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container p {
  font-size: 0.8rem;
  position: absolute;
  font-weight: 700;
  top: 58%;
  transform: translateY(-50%);
}
