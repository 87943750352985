#icon-loading-container {
  position: relative;
}

#icon-loading-container > .loader-container {
  background-color: transparent;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
}

#icon-loading-container > .loader-container svg {
  display: flex;
  fill: #273f48;
}

.back-button {
  margin: 20px auto;
  padding: 0px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.back-button svg {
  cursor: pointer;
}

#podcast-page {
  padding-right: 20px;
}
.podcast-page-container {
  background-color: white;
  width: 500px;
  height: 88vh;
  overflow: auto;
  margin: 0px auto;
}

.podcast-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 5px;
}

.podcast-page-title-box {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.podcast-page-title-image img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.podcast-page-title-text p,
.podcast-page-title-text h2,
.episode-section-container h2 {
  padding: 0px;
  margin: 0px;
}

.podcast-page-title-text h2,
.episode-section-container h2 {
  font-size: 24px;
  letter-spacing: 0.15px;
  color: #273f48;
}

.episode-section-container h2 {
  display: flex;
  align-items: center;
}

.podcast-page-title-text h2 {
  width: 300px;
}
.podcast-page-title-text p,
.empty-message p {
  margin-top: 12px;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #273f48;
}

.empty-message p {
  width: 300px;
}

.empty-message p a {
  text-decoration: none;
  color: rgba(226, 81, 76, 1);
  font-weight: 600;
}

.empty-message p a:hover {
  text-decoration: underline;
}

#podcast-page .podcast-page-title-box button,
#podcast-page .episode-item button,
#podcast-page .podcast-page-title-content button {
  background-color: white;
  outline: none;
  padding: 2px 20px;
  margin-top: 12px;
  margin-right: 10px;
  box-sizing: border-box;
  border-radius: 32px;
  border: 2px solid rgba(226, 81, 76, 0.5);
  transition: all 0.3s ease-in-out;
  color: #273f48;
}

#podcast-page .episode-item button {
  margin-top: 0px;
  padding: 2px 15px;
  font-size: 0.8rem;
}

#podcast-page .podcast-page-title-box button:hover,
#podcast-page .episode-item button:hover,
#podcast-page .podcast-page-title-content button:hover {
  border: 2px solid rgba(226, 81, 76, 1);
}

.podcast-page-title-content {
  margin-top: 15px;
  padding: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #273f48;
}

.podcast-page-title-content hr {
  margin-top: 0.3rem;
}

/* Episode Section */
.episode-section-container {
  margin-top: 20px;
  padding: 20px;
  padding-top: 0px;
  position: relative;
}
.episode-section-container h2 {
  vertical-align: middle;
  margin-bottom: 30px;
  font-size: 1.3rem;
}

.episode-item {
  margin-bottom: 20px;
  position: relative;
}

.episode-item h3 {
  font-size: 1rem;
  letter-spacing: 0.15px;
}

.episode-item p {
  font-size: 0.9rem;
  margin-bottom: 8px;
  max-width: 80%;
}

/* Confirm Dialog */
#confirm-dialog {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
}

#confirm-dialog p {
  padding-left: 5px;
  border-left: 5px solid #273f48;
  font-size: 0.9rem;
  margin-bottom: 0px;
  align-self: center;
  font-weight: 500;
}

#confirm-dialog button.yes,
#confirm-dialog button.no,
#confirm-dialog button.yes:hover,
#confirm-dialog button.no:hover {
  color: white;
  outline: none;
  border-radius: 0px;
  border: none;
  font-size: 0.8rem;
  padding: 2px 10px;
  margin: 0px;
  margin-left: 4px;
  height: fit-content;
  border-radius: 32px;
  transition: all 0.3s ease-in-out;
  backface-visibility: hidden;
}

#confirm-dialog button.yes {
  background-color: #e2514c;
}

#confirm-dialog button.no {
  background-color: #273f48;
}

#confirm-dialog button.yes:hover,
#confirm-dialog button.no:hover {
  transform: translateY(-1px);
}

p.deleting-message {
  margin-top: 20px;
  font-size: 0.9rem;
  margin-bottom: 0px;
  align-self: center;
  font-weight: 700;
}

p.deleting-message.deleted {
  color: rgba(226, 81, 76, 1);
}

#podcast-page button#episode-upload-button {
  background-color: #e2514c;
  color: white;
  font-weight: 700;
  padding: 3px 15px;
}

#podcast-page button#episode-upload-button:hover {
  transform: translateY(-2px);
}

#podcast-page .fa-refresh {
  font-size: 28px;
  cursor: pointer;
}

/* Upload in progress */
.upload-in-progress {
  position: relative;
  background-color: white;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #273f48;
  font-weight: 700;
  top: 50%;
  transform: translateY(-50%);
}

.upload-in-progress .fa-check-square {
  margin-left: 10px;
  color: #273f48;
  font-size: 1.3rem;
}

.upload-in-progress.completed {
  background-color: #fff;
  flex-direction: row;
}

/* NEW Badge */
.new-badge {
  color: white;
  font-weight: 400;
  border-radius: 10px;
  font-size: 0.7rem;
  margin-left: 10px;
  padding: 5px;
  background-color: #bbc1c4;
}

/* Delete dialog */
.button-container {
  position: relative;
}
.delete-dialog {
  top: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #273f48;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-radius: 10px;
}

.delete-dialog p,
.delete-dialog .fa-info-circle {
  height: 100%;
  color: white;
  margin: 0px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.delete-dialog .fa-info-circle {
  margin-right: 10px;
}
#podcast-page .delete-dialog button {
  background-color: transparent;
  color: white;
  padding: 0px 4px;
  margin: 0px;
  margin-left: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
}

#podcast-page .delete-dialog button:hover {
  text-decoration: underline;
  border: none;
}

.delete-episode {
  background-color: #e2514c;
}

.subscribe-button svg {
  margin-right: 3px;
}
