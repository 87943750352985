#audio-player-container {
  color: #273f48;
  position: fixed;
  bottom: 0px;
  padding: 3px;
  width: 100vw;
  z-index: 1039;
  background: #ffffff;
  box-shadow: 4px 8px 20px 10px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: 300px 1fr;
  justify-items: center;
}

#audio-player-container .audio-header-section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
