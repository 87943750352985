.landing-navbar {
  display: flex;
  justify-content: space-between;
}
.landing-navbar .links-container {
  display: flex;
  flex-direction: row;
}
.landing-navbar .links-container a {
  margin-left: 20px;
}
.landing-page.browse-link {
  height: 45px;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.landing-page.browse-link:hover {
  text-decoration: underline;
}

.landing-page.signup-link,
.landing-page.login-link {
  line-height: 44px;
  height: 44px;
  transition: all 0.5s ease-in-out;
  color: #e2514c;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(226, 81, 76, 0.15);
  border-radius: 32px;
  border: 2px solid #fff;
}

.landing-page.login-link {
  background: #e2514c;
  color: #fff;
}

/* .landing-page.signup-link:hover {
  background-color: #e2514c;
  color: #fff;
  border: 2px solid #fff;
} */

.landing-page.login-link:hover {
  background-color: #fff;
  color: #e2514c;
  border: 2px solid #fff;
}

.landing-navbar {
  width: 100%;
  height: 12vh;
  padding: 20px 5em;
  padding-right: 2em;
  background-color: #e2514c;
}

.landing-page.container-red,
.landing-page.container-white,
.landing-page.container-metal {
  background-color: #e2514c;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.landing-page h1 span {
  color: #e2514c;
}
.landing-page.container-white {
  background-color: #fff;
  align-items: center;
}

.landing-page.hero-description,
.landing-page.mission-description {
  padding-left: 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  backface-visibility: hidden;
}

.landing-page.hero-description h1,
.landing-page.hero-description p {
  color: #fff;
}

.landing-page.hero-description h1 {
  width: 320px;
  font-size: 60px;
  line-height: 74px;
  letter-spacing: 0.15px;
}
.landing-page.hero-description p,
.landing-page.mission-description p {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 415px;
  font-weight: 400;
  font-size: 1rem;
  font-size: 20px;
  line-height: 24px;
}

.landing-page.mission-description p {
  color: #273f48;
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: 0.08px;
}

.landing-page.hero-description button,
.landing-page.mission-description button {
  margin-top: 30px;
  background-color: #fff;
  border: 3px solid #fff;
  color: #e2514c !important;
  padding: 10px 16px;
  width: 210px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(226, 81, 76, 0.15);
  border-radius: 32px;
  transition: 0.3s all ease-in-out;
}

.landing-page.mission-description button {
  color: #e2514c;
  margin-top: 2px;
  border: 3px solid #e2514c;
  box-shadow: none;
}

.landing-page.hero-description button:hover,
.landing-page.mission-description button:hover {
  background-color: #e2514c;
  color: #fff !important;
  border: 3px solid #fff;
}

.landing-page.hero-image {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.landing-page.hero-image svg {
  position: absolute;
  top: 0px;
}

.landing-page.hero-image svg:nth-of-type(4) {
  top: 120px;
  left: 170px;
}
.landing-page.hero-image svg:nth-of-type(3) {
  top: 370px;
  left: 100px;
}
.landing-page.hero-image svg:nth-of-type(2) {
  top: 190px;
  left: 420px;
}
.landing-page.hero-image svg:nth-of-type(1) {
  top: 420px;
  left: 450px;
}

.landing-page.hero-image img {
  height: 100vh;
  z-index: 1;
}
.landing-page.mission-image img {
  width: 80%;
  height: 80%;
  margin-left: 4em;
}

#landing-services {
  height: fit-content;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.service-1,
.service-2,
.service-3 {
  height: 50vh;
  color: #fff;

  text-align: center;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.service-1 p,
.service-2 p,
.service-3 p {
  vertical-align: text-top;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  width: 300px;
}

.service-1 h2,
.service-2 h2,
.service-3 h2 {
  height: 123px;
  width: 216px;
  color: white;
}

#search {
  grid-template-columns: 1fr;
  height: fit-content;
}

.landing-page.search-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 2em;
}

.landing-page.search-container h1 {
  text-align: center;
  font-size: 34px;
  color: #273f48;
  margin-bottom: 32px;
  max-width: 80%;
}

.landing-page.search-container .search-images {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
}
.landing-page.search-container .search-images img {
  margin: 2px 3px;
  margin-bottom: 0px;
}

.landing-nav-search {
  margin-top: 20px;
  position: relative;
  width: 50%;
  height: fit-content;
  margin-bottom: 32px;
}

.landing-nav-search svg {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.landing-nav-search input {
  width: 100%;
  border-radius: 48px;
  padding-left: 40px;
  background: #e8e8e8;
  opacity: 0.5;
  height: 36px;
  border: none;
  border-bottom: 1px solid #ced4da;
  box-sizing: border-box;
  outline: none;
}

.landing-page.container-metal {
  background-color: #273141;
  position: relative;
}

.landing-page.container-metal.feedback {
  padding: 50px;
}

.landing-cards {
  padding: 18px 40px;
  width: 300px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
  border-radius: 8px;
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.landing-cards h4 {
  margin-top: 1.2rem;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin: 0px 0px;
  color: #273f48;
}
.landing-cards.card1,
.landing-cards.card2 {
  width: 300px;
  height: 250px;
  left: 70%;
}
.landing-cards.card1 {
  top: 30vh;
}
.landing-cards.card2 {
  top: 75vh;
}
.landing-cards.card3 {
  left: 46.5%;
  top: 52.5vh;
}

.landing-page.feedback-description h2 {
  color: #fff;
  padding-left: 10%;
  font-weight: 600;
  font-size: 48px;
  color: #f7f7fa;
  font-size: 45px;
}

.landing-page.feedback-description p {
  color: #fff;
  padding-left: 10%;
  font-size: 16px;
  line-height: 24px;
  color: #ffffffe6;
}

.landing-page.container-white.make-podcast {
  height: fit-content;
  padding: 40px;
}

button.landing-page.podcast-button {
  margin-top: 2em;
  background-color: #e2514c;
  color: #fff !important;
}

button.landing-page.podcast-button:hover {
  border-radius: 3px solid #e2514c;
}

.landing-page.make-podcast p {
  margin: 0px;
  color: #e2514c;
  margin-top: 10px;
}
.landing-page.make-podcast h4 {
  color: #273f48;
  width: 443px;
  height: 108px;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 54px;
}

blockquote {
  position: relative;
}

.landing-page i {
  position: absolute;
  color: #e2514c;
  left: -25px;
}

.landing-page.container-metal.landing-footer {
  display: grid;
  height: 60vh;
  grid-template-columns: 1fr;
}

.footer-links-container {
  padding: 5em;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 20% 1fr;
  justify-items: center;
}

.footer-links {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.footer-links div {
  display: flex;
  flex-direction: column;
}

.footer-links div h4 {
  justify-self: flex-start;
  color: white;
}

.footer-links a {
  color: white;
  margin-top: 20px;
  text-decoration: none;
}
